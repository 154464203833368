import React from "react"
import Header from "../components/header"
import Heading from "../components/heading"
import Nav from "../components/nav"
import Welcome from "../components/welcome"
import BoxContainer from "../components/boxcontainer"
import Box from "../components/clickbox"
import revojam from "../static/revojam.png"
import python from "../static/python.png"
import podcast from "../static/palchemy.png"
import blog from "../static/blog.png"
import bulb from "../static/bulb.png"
import Footer from "../components/footer"
import { Link } from "gatsby"


export default function Home() {
  return (
  	<div style={{position: "relative", minHeight: "100vh"}}>
	  	<Header>
	  		<Heading title='Coby Sheehan' href='/about/' linkText='About me' />
	  		<Nav />
	  	</Header>
	  	<Welcome />
	  	<BoxContainer>
	  	<Link to='/coding'>
	  		<Box text='Code stuff' img={python} 
	  			 description="View several programs I've written (mostly in python), including a few physics simulations." 
	  		/>
	  	</Link>
	  	<a target='_blank' rel="noreferrer" href="https://www.revojam.com">
	  		<Box text='Revojam' img={revojam} 
	  			 description="Revojam is a webapp a friend and I built. Using Spotify, it lets you join a live room with others where everyone can add songs to a group queue." 
	  		/>
	  	</a>
	  	<Link to='/podcast'>
	  		<Box text='Podcast' img={podcast} 
	  			 description="This was my Senior project in high school. Each interview is a new take on how to achieve a fulfilling life." 
	  		/>
	  	</Link>
	  	<Link to='/blog'>
	  		<Box text='Blog' img={blog} 
	  			 description="Writings of (sometimes) strange stuff I think about often." 
	  		/>
	  	</Link>
	  	<Link to='/'>
	  		<Box text='New Projects' img={bulb} 
	  			 description="You can't actually click on this one (yet!), but I was proud of this lightbulb drawing. Currently, I'm working on programming an iOS app with ReactNative, and building a roblox game!" 
	  		/>
	  	</Link>
	  	</BoxContainer>
	  	<Footer  />
	 </div>
  )
}
